<template>
  <div ref="widget">
    <!--toggle-->
    <div
      class="help-widget fixed bottom-6 right-4 z-[1000] flex size-12 cursor-pointer items-center justify-center rounded-full bg-primary p-2.5 shadow-md transition ease-out hover:scale-110 active:scale-90 sm:right-6"
      @click="isOpened = !isOpened"
    >
      <IconHelp class="size-8 text-white outline-none transition" />
    </div>
    <!--widget-->
    <Transition v-bind="fadeShiftScale">
      <div
        v-if="isOpened"
        class="fixed bottom-0 right-0 z-[9997] overflow-hidden bg-white p-6 dark:bg-neutral-900 max-sm:left-0 max-sm:top-0 sm:bottom-24 sm:right-14 sm:h-[600px] sm:w-[100%] sm:max-w-[365px] sm:rounded-xl"
        :class="{ 'shadow-[0_0_50px_-0px_rgba(0,0,0,0.16)]': !content }"
      >
        <div class="absolute inset-0">
          <!--header-->
          <div
            class="sticky flex h-16 flex-col items-center bg-primary py-4 transition"
            :class="{
              'h-28': activeViewIndex === 2,
            }"
            style="transition: height 300ms"
          >
            <p
              v-if="navigationItems[activeViewIndex].showHeader !== false"
              class="mt-0.5 text-xl text-white"
              v-text="navigationItems[activeViewIndex].title"
            />
            <div
              class="mt-2 max-h-0 w-full overflow-hidden px-4 opacity-0"
              :class="{ 'max-h-10 opacity-100': activeViewIndex === 2 }"
              style="
                transition:
                  max-height 300ms,
                  opacity 200ms;
              "
            >
              <input
                v-model="searchValue"
                class="dark:text-black w-full rounded-md px-4 py-1.5 outline-none"
                placeholder="Поиск"
              />
            </div>
          </div>
          <!--content-->
          <div
            class="absolute left-0 right-0 -z-10 h-[400px] -translate-y-1/2 scale-y-0 bg-gradient-to-b from-primary to-transparent transition-transform"
            :class="{ 'translate-y-0 scale-y-100': activeViewIndex === 0 }"
          />
          <!--pages-->
          <div
            v-if="activeViewIndex === 0"
            class="absolute left-0 right-0 z-10 -mt-8 px-6"
          >
            <div class="flex w-full items-center justify-between">
              <p
                class="text-2xl font-semibold text-white md:text-3xl"
                v-text="'Нужна помощь?'"
              />
              <button @click="closeWidget">
                <IconX class="size-6 text-white" />
              </button>
            </div>
          </div>

          <div class="h-full overflow-y-auto" v-if="activeViewIndex === 0">
            <div class="space-y-4 p-6">
              <div
                class="text-black group flex cursor-pointer items-center justify-between rounded-md bg-card px-4 pb-5 pt-4 shadow-lg"
                @click="openAppealsModal"
              >
                <div class="flex flex-1 flex-col">
                  <span
                    class="mb-0.5 font-medium transition-colors group-hover:text-primary"
                    v-text="'Проблема на сайте'"
                  />
                  <span
                    class="max-w-[80%] text-xs text-black-800 dark:text-black-300"
                    v-text="'Сообщить о проблеме на сайте'"
                  />
                </div>

                <IconMessage class="size-5 text-primary" />
              </div>

              <div
                class="text-black group flex flex-col rounded-md bg-card p-4 text-sm shadow-lg"
              >
                <span
                  >Email:
                  <a
                    class="text-primary-700"
                    href="mailto:help@molodaya-arctica.ru"
                    >help@molodaya-arctica.ru</a
                  >
                </span>

                <ul class="mt-1 space-y-0.5">
                  <li
                    v-for="item in links"
                    :key="item.link"
                    class="text-xs text-black-800 dark:text-black-300"
                  >
                    <RouterLink
                      :to="item.link"
                      v-text="item.title"
                      class="block py-1 transition-colors hover:text-primary"
                      @click="closeWidget"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            class="h-[calc(100%_-_136px)] overflow-y-auto"
            v-if="activeViewIndex === 1"
          >
            <LoadingView :is-loading="isPending">
              <div class="p-6">
                <span v-if="!data.resources.length" class="text-center"
                  >На текущий момент у вас нет обращений</span
                >
                <div v-else class="flex flex-col space-y-3">
                  <RouterLink
                    v-for="item in data.resources"
                    :key="item.id"
                    :to="`/profile/support/${item.id}`"
                    class="relative"
                    @click="closeWidget"
                  >
                    <UIPlane
                      title-classes="text-sm mr-4"
                      :title="`№${item.id} — ${item.title}`"
                      :caption="`от ${getDateFormat(item.created_at)}`"
                    />

                    <span
                      v-if="item.notifications_count"
                      class="absolute -right-2 -top-2 flex h-4 w-4"
                    >
                      <span
                        class="absolute inline-flex h-full w-full animate-ping rounded-full bg-sky-400 opacity-75"
                      />
                      <span
                        class="relative inline-flex h-4 w-4 rounded-full bg-sky-500"
                      />
                    </span>
                  </RouterLink>
                </div>
              </div>
            </LoadingView>
          </div>
          <!--FAQ-->
          <div
            class="h-[calc(100%_-_180px)] overflow-y-auto"
            v-if="activeViewIndex === 2"
          >
            <LoadingView :is-loading="isPendingQuestions">
              <div class="space-y-2 p-4">
                <div
                  v-for="item in faqSearchResults"
                  :key="item.id"
                  class="cursor-pointer rounded-md bg-neutral-100 p-4 dark:bg-black-900"
                  @click="content = item"
                >
                  <p v-text="item.title" />
                </div>
              </div>
            </LoadingView>
          </div>
        </div>
        <!--bottom navigation-->
        <div
          class="absolute bottom-0 left-0 right-0 flex items-center justify-around border-t border-black-100 bg-neutral-50 px-6 pb-2 pt-3 dark:border-neutral-700 dark:bg-neutral-900"
        >
          <div
            v-for="(item, idx) in navigationItems"
            :key="idx"
            class="flex cursor-pointer select-none flex-col items-center text-black-800 hover:text-black-950 dark:text-black-300 dark:hover:text-black-500"
            :class="{
              'hover:text-brand-4 !text-primary hover:opacity-80 dark:!text-purple-400':
                activeViewIndex === idx,
              'pointer-events-none opacity-30': item.disabled,
            }"
            @click="changeActiveTab(idx)"
          >
            <component class="size-6" :is="item.icon" />
            <p class="mt-1 text-sm" v-text="item.tab ?? item.title" />
          </div>
        </div>
      </div>
    </Transition>
    <!--view article-->
    <Transition v-bind="content ? fadeSlideLeft : fadeSlideRight">
      <div
        v-if="isOpened && content"
        class="fixed bottom-0 left-0 right-0 top-0 z-[9999] h-full overflow-hidden bg-white py-0 shadow-[0_0_50px_-0px_rgba(0,0,0,0.16)] dark:bg-neutral-800 sm:bottom-24 sm:left-auto sm:right-14 sm:top-auto sm:h-[600px] sm:max-w-[480px] sm:rounded-lg"
      >
        <div class="h-full overflow-auto overscroll-contain sm:h-[600px]">
          <div
            class="sticky top-0 flex items-center justify-between gap-4 border-b border-black-200 bg-white px-6 py-4 dark:border-black-500 dark:bg-neutral-800"
          >
            <Heading class="flex-1" tag="4" styles="4" :title="content.title" />
            <IconX
              class="size-7 cursor-pointer rounded-md border border-neutral-200 bg-white p-1 hover:bg-black-100 dark:border-neutral-600 dark:bg-transparent dark:hover:bg-black-700"
              @click="content = null"
            />
          </div>
          <div
            class="prose my-4 px-6 text-black-900 dark:text-black-100 [&>.container]:px-0"
            v-html="content.content"
          />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import LoadingView from '@/components/glass/loading-view'
import { useQueryClient } from '@tanstack/vue-query'
import { onClickOutside } from '@vueuse/core'
import { useFuse } from '@vueuse/integrations/useFuse'
import { computed, reactive, ref, watch } from 'vue'
import { useModal, useModalSlot } from 'vue-final-modal'

import { useAuthStore } from '@/stores/auth.js'

import {
  IconDialpad,
  IconHelp,
  IconHome,
  IconMessage,
  IconMessageCircle,
  IconX,
} from '@tabler/icons-vue'

import ModalContainer from '@/components/common/ModalContainer.vue'
import Heading from '@/components/glass/heading'
import AppealsModal from '@/components/modals/AppealsModal.vue'
import UIPlane from '@/components/ui/UIPlane.vue'
import { useCatalog } from '@/composables/useCatalog.js'
import { useHttpQuery } from '@/core/http.js'
import { getDateFormat } from '@/utils/date.js'
import {
  fadeShiftScale,
  fadeSlideLeft,
  fadeSlideRight,
} from '@/utils/transitions.js'

const isOpened = ref(false)
const activeViewIndex = ref(0)

const authStore = useAuthStore()

const widget = ref(null)

onClickOutside(widget, (event) => closeWidget())

function closeWidget() {
  isOpened.value = false
  activeViewIndex.value = 0
}

const navigationItems = computed(() => [
  { title: 'Главная', icon: IconHome, showHeader: false },
  { title: 'Обращения', icon: IconMessageCircle, disabled: !authStore.user },
  { title: 'Вопросы и ответы', tab: 'Вопросы', icon: IconHelp },
])

const links = ref([
  {
    title: 'Политика конфиденциальности',
    link: '/policy',
  },
  {
    title: 'Пользовательское соглашение',
    link: '/agreement',
  },
  // {
  //   title: 'Общая информация о проекте',
  //   link: '/about/general',
  // },
])

const content = ref(null)

const searchValue = ref('')

const queryClient = useQueryClient()

const enableFetch = reactive({
  appeals: false,
  questions: false,
})

watch(
  () => authStore.isLoggedIn,
  (value) => {
    if (!value) queryClient.invalidateQueries({ queryKey: ['appeals'] })
  },
)

const {
  state: { data, isPending, isError, error, refetch },
  page,
  pageState,
} = useCatalog({
  enabled: () => enableFetch.appeals,
  queryKey: 'appeals',
  endpoint: '/appeals',
})

const { data: questions, isPending: isPendingQuestions, refetch: refetchQuestions } = useHttpQuery({
  queryOptions: {
    queryKey: ['help', 'questions'],
    select: (data) => data.resources,
    enabled: () => enableFetch.questions,
  },
  url: '/questions',
})

const { results } = useFuse(searchValue, questions, {
  matchAllWhenSearchEmpty: true,
  fuseOptions: {
    keys: ['id', 'title', 'content'],
    threshold: 0.1,
  },
})

const faqSearchResults = computed(() => {
  return results.value.map((i) => i.item)
})

function changeActiveTab(index) {
  if (index === 1 && authStore.isLoggedIn && !data.value) {
    refetch()
  }

  if (index === 2 && !questions.value) {
    refetchQuestions()
  }

  activeViewIndex.value = index
}

function openAppealsModal() {
  closeWidget()
  const { close } = useModal({
    defaultModelValue: true,
    component: ModalContainer,
    attrs: {
      containerClass: 'max-w-[620px] w-full m-auto p-6 md:p-10',
      onClose() {
        close()
      },
    },
    slots: {
      default: useModalSlot({
        component: AppealsModal,
        attrs: {
          onClose() {
            close()
          },
        },
      }),
    },
  })
}
</script>
