<template>
  <nav
    ref="navEl"
    class="sticky left-0 right-0 top-0 z-[100] flex justify-center bg-white/30 py-6 backdrop-blur-[36px] transition-colors duration-500 dark:bg-black-950/70"
    :class="{
      'dark:!bg-dark/70': $route.path === '/' && onTop && !isMobileMenuOpened,
      '!bg-light dark:!bg-black-950': isMobileMenuOpened,
    }"
  >
    <div class="container flex items-center justify-between">
      <MALogo :class="{ 'max-sm:hidden': isSearching }" />
      <div
        class="flex flex-1 items-center justify-center overflow-visible max-lg:hidden"
        @mouseleave="lastItem = null"
      >
        <transition v-bind="fade" mode="out-in">
          <div v-if="!isSearching" class="mr-8 flex items-center gap-1">
            <component
              v-for="(item, idx) in items"
              :key="idx + $route.path"
              :is="item?.subitems ? 'div' : RouterLink"
              :to="item?.to"
              class="group relative rounded-md"
              :class="{
                'bg-purple-100 *:text-purple-700 dark:bg-purple-800 dark:*:text-purple-100':
                  $route.path === item?.to ||
                  item?.subitems?.some((i) => $route.path.startsWith(i.to)),
              }"
              @mouseover="
                () => {
                  lastItem = idx
                }
              "
            >
              <div
                class="flex cursor-pointer items-center gap-2 px-3 py-2 text-black-700 transition-colors hover:text-purple-700 dark:text-white dark:hover:text-purple-400"
              >
                <component class="size-4 min-w-4 stroke-2" :is="item.icon" />
                <span class="text-sm font-semibold" v-text="item.title" />
                <IconChevronDown
                  v-if="item.subitems"
                  class="size-4 min-w-4 stroke-2 transition-transform duration-300 group-hover:rotate-180"
                />
              </div>

              <div
                v-if="items?.[lastItem]?.subitems"
                class="pointer-events-auto invisible absolute left-1/2 hidden min-w-[440px] !max-w-lg -translate-x-1/2 overflow-visible pt-8 group-hover:!visible sm:block"
              >
                <ul
                  class="space-y-0.5 rounded-lg bg-white p-3 shadow-2xl ring-1 ring-black-950/5 dark:bg-dark dark:ring-white/5"
                >
                  <router-link
                    is="li"
                    :to="subitem.to"
                    v-for="(subitem, sIdx) in items?.[lastItem]?.subitems"
                    :key="sIdx"
                    class="flex cursor-pointer items-center space-x-4 rounded px-3 py-2.5 text-black-950 transition-colors hover:bg-block dark:text-white dark:hover:bg-purple-900/60"
                    @click="makeBlock"
                  >
                    <component
                      class="dark:text-dark-300 size-10 rounded-lg bg-block p-2 text-black-900 dark:bg-purple-1000 dark:text-white"
                      :class="{
                        '!text-purple-600 dark:!text-purple-500':
                          $route.path === subitem?.to,
                      }"
                      :is="subitem.icon"
                    />
                    <div
                      :class="{
                        '!text-purple-600 dark:!text-purple-300':
                          $route.path === subitem?.to,
                      }"
                    >
                      <p class="font-bold" v-text="subitem.title" />
                      <p
                        class="mt-0.5 text-sm tracking-tight opacity-80"
                        v-text="subitem.caption"
                      />
                    </div>
                  </router-link>
                </ul>
              </div>
            </component>
          </div>

          <!--desktop search-->
          <div v-else class="relative" ref="searchArea">
            <FormKit
              ref="search"
              :classes="{
                outer: '!mb-0 mr-2',
                inner: '!mb-0 h-[40px] min-w-[532px]',
              }"
              placeholder="Поиск"
              type="text"
              v-model="searchValue"
            />
            <!--search results-->
            <div
              v-if="isSearching"
              class="pointer-events-auto absolute left-0 right-0 top-10 -ml-0.5 w-full overflow-visible pt-4"
            >
              <div
                class="space-y-0.5 rounded-lg bg-white p-3 shadow-2xl ring-1 ring-black-950/5 dark:bg-dark dark:ring-white/5"
              >
                <transition v-bind="fade" mode="out-in">
                  <Loading
                    v-if="searchLoading"
                    class="mx-auto"
                    :is-loading="searchLoading"
                  />
                  <ul v-else-if="searchResult.length > 0">
                    <router-link
                      v-if="searchResult"
                      is="li"
                      v-for="(item, idx) in searchResult"
                      :key="idx"
                      :to="`/${routeMapper?.[item.type.value] ?? item.type.value}/${item.id}`"
                      class="flex cursor-pointer items-center space-x-4 rounded px-3 py-2.5 text-black-950 transition-colors hover:bg-block dark:text-white dark:hover:bg-purple-900/60"
                      @click="isSearching = false"
                    >
                      <div>
                        <p class="line-clamp-2 font-bold" v-text="item.title" />
                        <p
                          class="mt-0.5 text-sm tracking-tight opacity-80"
                          v-text="item.type.label"
                        />
                      </div>
                    </router-link>
                  </ul>
                  <ul v-else-if="!searchValue">
                    <li class="p-3" v-text="'Введите запрос'" />
                  </ul>
                  <ul v-else-if="searchResult.length === 0">
                    <li class="p-3" v-text="'Ничего не найдено'" />
                  </ul>
                </transition>
              </div>
            </div>
          </div>
        </transition>

        <Button
          class="!bg-transparent text-black-700 transition-opacity duration-300 hover:opacity-40 dark:text-white"
          variant="ghost"
          :icon="isSearching ? IconX : IconSearch"
          @click.stop="handleSearchClick"
        />
      </div>

      <div
        ref="searchAreaMobile"
        class="flex items-center gap-1.5 max-md:w-full sm:gap-3"
      >
        <div v-if="!isDesktop" class="relative flex-1 sm:ml-8" ref="searchArea">
          <!--mobile search-->
          <transition v-bind="fade">
            <div v-if="!isDesktop && isSearching">
              <FormKit
                ref="mobileSearch"
                :classes="{
                  outer: '!mb-0 mr-2',
                  inner: '!mb-0 h-[36px] w-full',
                }"
                placeholder="Поиск"
                type="text"
                v-model="searchValue"
              />
              <!--search results-->
              <div
                v-if="isSearching"
                class="pointer-events-auto fixed left-6 right-6 top-14 overflow-visible pt-4 sm:absolute sm:left-0 sm:right-0 sm:top-8 sm:-ml-0.5 sm:w-full"
              >
                <div
                  class="space-y-0.5 rounded-lg bg-white p-3 shadow-2xl ring-1 ring-black-950/5 dark:bg-dark dark:ring-white/5"
                >
                  <transition v-bind="fade" mode="out-in">
                    <Loading
                      v-if="searchLoading"
                      class="mx-auto"
                      :is-loading="searchLoading"
                    />
                    <ul v-else-if="searchResult.length > 0">
                      <router-link
                        v-if="searchResult"
                        is="li"
                        v-for="(item, idx) in searchResult"
                        :key="idx"
                        :to="`/${routeMapper?.[item.type.value] ?? item.type.value}/${item.id}`"
                        class="flex cursor-pointer items-center space-x-4 rounded px-3 py-2.5 text-black-950 transition-colors hover:bg-block dark:text-white dark:hover:bg-purple-900/60"
                        @click="isSearching = false"
                      >
                        <div>
                          <p
                            class="line-clamp-2 font-bold"
                            v-text="item.title"
                          />
                          <p
                            class="mt-0.5 text-sm tracking-tight opacity-80"
                            v-text="item.type.label"
                          />
                        </div>
                      </router-link>
                    </ul>
                    <ul v-else-if="!searchValue">
                      <li class="p-3" v-text="'Введите запрос'" />
                    </ul>
                    <ul v-else-if="searchResult.length === 0">
                      <li class="p-3" v-text="'Ничего не найдено'" />
                    </ul>
                  </transition>
                </div>
              </div>
            </div>
          </transition>
        </div>

        <Button
          variant="neutral"
          size="sm"
          class="!bg-black-100 hover:!bg-black-200 dark:!bg-black-800 dark:hover:!bg-black-700 lg:hidden"
          :icon="isSearching ? IconX : IconSearch"
          @click="toggleSearch"
        />

        <Toggle
          class="flex size-10 items-center justify-center rounded-md bg-black-100 text-purple-800 hover:bg-black-200 dark:bg-black-800 dark:text-yellow-300 dark:hover:bg-black-700 max-lg:hidden"
          :model-value="isDark"
          @click="toggleDark()"
        >
          <template #true>
            <IconMoon class="size-5" />
          </template>
          <template #false>
            <IconSun class="size-5" />
          </template>
        </Toggle>

        <Button
          v-if="!auth.user"
          variant="neutral"
          size="sm"
          class="!bg-black-100 hover:!bg-black-200 dark:!bg-black-800 dark:hover:!bg-black-700 lg:hidden"
          :icon="IconUser"
          @click="callAuthModal"
        />
        <RouterLink
          v-else
          variant="neutral"
          size="sm"
          class="relative flex size-9 items-center justify-center rounded-md !bg-black-100 text-neutral-foreground hover:!bg-black-200 dark:!bg-black-800 dark:hover:!bg-black-700 lg:hidden"
          to="/profile"
          @click="
            () => {
              isSearching = false
              searchValue = ''
              isMobileMenuOpened = false
              mobileMenuSelectedItem = null
            }
          "
        >
          <IconUser class="absolute inset-auto size-5" />
        </RouterLink>
        <Toggle
          class="flex size-9 items-center justify-center rounded-md !bg-black-100 text-neutral-foreground hover:!bg-black-200 dark:!bg-black-800 dark:hover:!bg-black-700 lg:hidden"
          :content-transition="
            isMobileMenuOpened ? toggleRotate1 : toggleRotate2
          "
          mode=""
          :model-value="isMobileMenuOpened"
          @click="toggleMobileMenu"
        >
          <template #true>
            <IconX class="absolute inset-auto size-5" />
          </template>
          <template #false>
            <IconMenu2 class="absolute inset-auto size-5" />
          </template>
        </Toggle>

        <Button
          v-if="!auth.user"
          text="Войти"
          class="w-[136px] max-lg:hidden"
          @click="callAuthModal"
        />
        <Button
          v-else
          is="router-link"
          :text="auth.user.title"
          class="w-[136px] max-lg:hidden"
          to="/profile"
        />
      </div>
    </div>

    <!--mobile menu-->
    <div
      class="fixed bottom-0 left-0 right-0 top-[84px] flex h-[calc(100svh_-_84px)] flex-col justify-between bg-light px-3 py-10 opacity-100 transition-all dark:bg-black-950"
      :class="{
        'pointer-events-none !h-[calc(0px)] overflow-hidden !opacity-0':
          !isMobileMenuOpened,
      }"
    >
      <transition
        v-bind="
          mobileMenuSelectedItem === null ? fadeSlideRight : fadeSlideLeft
        "
        mode="out-in"
      >
        <div v-if="mobileMenuSelectedItem === null" class="space-y-2">
          <div
            v-for="(item, idx) in items"
            :key="idx"
            class="cursor-pointer rounded px-3 py-2 text-black-800 hover:bg-black-700/10 active:bg-black-700/10 dark:text-black-200 hover:dark:bg-black-900/50 active:dark:bg-black-900/50"
            @click="
              () => {
                if (item.subitems) mobileMenuSelectedItem = idx
                else {
                  router.push(item.to)
                  isMobileMenuOpened = false
                }
              }
            "
          >
            <div class="flex items-center justify-between text-xl">
              <div class="flex items-center space-x-3">
                <component :is="item.icon" class="size-5" />
                <p v-if="item.subitems" v-text="item.title" />
                <p v-else v-text="item.title" />
              </div>
              <IconChevronRight v-if="item.subitems" class="size-6" />
            </div>
          </div>
        </div>
        <div v-else>
          <div class="space-y-2">
            <div
              class="group mb-6 ml-2 flex cursor-pointer items-center"
              @click="mobileMenuSelectedItem = null"
            >
              <IconChevronLeft
                class="size-4 transition-all group-hover:-translate-x-1"
              />
              <p class="ml-1 text-sm font-semibold" v-text="'Обратно в меню'" />
            </div>
            <div
              v-for="(subitem, sidx) in items[mobileMenuSelectedItem].subitems"
              :key="sidx"
              class="flex cursor-pointer items-center space-x-3 rounded px-3 py-2 text-lg text-black-800 hover:bg-black-700/10 active:bg-black-700/10 dark:text-black-200 hover:dark:bg-black-900/50 active:dark:bg-black-900/50"
              @click="
                () => {
                  router.push(subitem.to)
                  isMobileMenuOpened = false
                }
              "
            >
              <component :is="subitem.icon" class="size-5" />
              <router-link
                v-if="subitem.to"
                :to="subitem.to"
                v-text="subitem.title"
              />
            </div>
          </div>
        </div>
      </transition>

      <Toggle
        class="flex size-10 items-center justify-center rounded-md bg-black-100 text-purple-800 hover:bg-black-200 dark:bg-black-800 dark:text-white dark:hover:bg-black-700"
        :model-value="isDark"
        @click="toggleDark()"
      >
        <template #true>
          <IconMoon class="size-5" />
        </template>
        <template #false>
          <IconSun class="size-5" />
        </template>
      </Toggle>
    </div>
    <div v-if="auth.isImpersonate" class="absolute inset-x-0 bottom-0 translate-y-full bg-white/30 dark:bg-black-950/70 backdrop-blur-[36px] py-2">
      <div class="container">
        <div class="flex justify-between">
          <div class="flex items-center gap-3">
            <IconSpy class="size-5" />
            <span>Вы находитесь под чужой учетной записью</span>
          </div>
          <Button reverse size="xs" :icon="IconSpyOff" text="Выйти" @click="auth.logoutImpersonate" />
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import {
  breakpointsTailwind,
  onClickOutside,
  useBreakpoints,
  useResizeObserver,
  useToggle,
  useWindowScroll,
  watchDebounced,
} from '@vueuse/core'
import { computed, nextTick, ref } from 'vue'
import { useModal, useModalSlot } from 'vue-final-modal'
import { RouterLink, useRouter } from 'vue-router'

import { useAuthStore } from '@/stores/auth.js'

import {
  IconBriefcase,
  IconCalendarEvent,
  IconChecklist,
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconContract,
  IconHeartSpark,
  IconMenu2,
  IconMoon,
  IconNews,
  IconPhoto,
  IconRocket,
  IconSearch,
  IconShoppingBag,
  IconSparkles,
  IconSpy,
  IconSpyOff,
  IconSun,
  IconUser,
  IconUsersGroup,
  IconX,
} from '@tabler/icons-vue'

import AuthCombine from '@/components/auth/AuthCombine.vue'
import MALogo from '@/components/common/MALogo.vue'
import ModalContainer from '@/components/common/ModalContainer.vue'
import Button from '@/components/glass/button'
import Toggle from '@/components/glass/toggle/Toggle.vue'
import useTheme from '@/core/theme.js'
import isBrowser from '@/utils/is-browser.js'
import { fade, fadeSlideLeft, fadeSlideRight } from '@/utils/transitions.js'

import useHttp from '../../core/http.js'
import Loading from '../common/Loading.vue'

const auth = useAuthStore()
const router = useRouter()

const isMobileMenuOpened = ref(false)
const mobileMenuSelectedItem = ref(null)

function toggleMobileMenu() {
  if (isMobileMenuOpened.value) {
    isMobileMenuOpened.value = false
    mobileMenuSelectedItem.value = null
    return
  }
  isSearching.value = false
  searchValue.value = ''
  isMobileMenuOpened.value = true
}

const breakpoints = useBreakpoints(breakpointsTailwind)
const isDesktop = ref(false)
const navEl = ref(null)

useResizeObserver(navEl, () => {
  isDesktop.value = breakpoints.isGreater('lg')

  if (isDesktop.value) isMobileMenuOpened.value = false
})

const items = ref([
  {
    title: 'Активности',
    icon: IconRocket,
    to: '/',
    subitems: [
      {
        title: 'Блог',
        icon: IconNews,
        to: '/blog',
        caption: 'Последние новости и полезная информация',
      },
      {
        title: 'Афиша',
        icon: IconCalendarEvent,
        to: '/afisha',
        caption: 'Мероприятия и активности',
      },
      {
        title: 'Вакансии волонтеров',
        icon: IconHeartSpark,
        to: '/vacancies',
        caption: 'Вакансии с Dobro.ru',
      },
      {
        title: 'Творчество',
        icon: IconSparkles,
        to: '/creations',
        caption: 'Предлагайте свои новости, делитесь творчеством',
      },
      {
        title: 'Задания',
        icon: IconChecklist,
        to: '/tasks',
        caption: 'Выполняйте задания и получайте баллы',
      },
      {
        title: 'Вакансии',
        icon: IconBriefcase,
        to: '/jobs',
        caption: 'Найдите вакансию по душе',
      },
      {
        title: 'Гранты',
        icon: IconContract,
        to: '/grants',
        caption: 'Подайте заявку на грант',
      },
    ],
  },
  {
    title: 'Партнеры',
    icon: IconUsersGroup,
    subitems: [
      {
        title: 'Молодежные пространства',
        icon: IconNews,
        to: '/spaces',
        caption: 'Центры профессионального развития молодежи',
      },
      {
        title: 'Информационные партнеры',
        icon: IconCalendarEvent,
        to: '/partners',
        caption: 'Наши партнеры',
      },
    ],
  },
  {
    title: 'Медиа',
    icon: IconPhoto,
    to: '/media',
  },
  {
    title: 'Магазин',
    icon: IconShoppingBag,
    to: '/shop',
  },
])
const isSearching = ref(false)
const isDark = useTheme()
const lastItem = ref(null)

const blocked = ref(false)

function makeBlock() {
  blocked.value = true
  setTimeout(() => {
    blocked.value = false
  }, 200)
}

const toggleDark = useToggle(isDark)

const { y } = useWindowScroll()
const onTop = computed(() => {
  return isBrowser ? y.value < window.innerHeight : false
})

function callAuthModal() {
  const { close } = useModal({
    defaultModelValue: true,
    showSwipeBanner: true,
    component: ModalContainer,
    attrs: {
      containerClass: 'max-w-[420px] w-full m-auto py-8 px-6',
    },
    slots: {
      default: useModalSlot({
        component: AuthCombine,
        attrs: {
          onClose() {
            close()
          },
        },
      }),
    },
  })
}

const searchValue = ref('')
const search = ref(null)
const mobileSearch = ref(null)
const searchLoading = ref(false)
const searchResult = ref([])
const searchArea = ref(null)
const searchAreaMobile = ref(null)

onClickOutside(searchArea, (evt) => {
  if (!isDesktop.value) return
  searchValue.value = ''
  isSearching.value = false
  evt.stopPropagation()
})
onClickOutside(searchAreaMobile, (evt) => {
  if (isDesktop.value) return
  searchValue.value = ''
  isSearching.value = false
  // evt.stopPropagation()
})

function focusOnInput(input) {
  setTimeout(() => {
    if (isSearching.value) {
      input.value?.$el?.querySelector('input').focus()
    }
  }, 400)
}

function handleSearchClick() {
  isSearching.value = !isSearching.value
  searchValue.value = ''
  focusOnInput(search)
}

let abortController = null

watchDebounced(
  searchValue,
  () => {
    makeSearch(searchValue.value)
  },
  { debounce: 200, maxWait: 1000 },
)

function makeSearch(value) {
  if (abortController) {
    abortController.abort()
  }

  abortController = new AbortController()

  searchLoading.value = true
  useHttp('/search', {
    method: 'GET',
    signal: abortController.signal,
    params: {
      query: value,
    },
  })
    .then(({ resources }) => {
      searchResult.value = resources
      setTimeout(() => {
        searchLoading.value = false
      }, 300)
    })
    .catch((e) => {
      if (e.cause.name !== 'AbortError')
        setTimeout(() => {
          searchLoading.value = false
        }, 300)
    })
}

function toggleSearch() {
  nextTick(() => {
    if (isSearching.value) {
      searchValue.value = ''
      isSearching.value = false
    } else {
      isMobileMenuOpened.value = false
      mobileMenuSelectedItem.value = null
      isSearching.value = true
      focusOnInput(mobileSearch)
    }
  })
}

const routeMapper = {
  events: 'afisha',
  posts: 'blog',
  activities: 'vacancies',
}

const toggleRotate1 = {
  enterActiveClass: 'ease-out duration-200',
  enterFromClass: '-rotate-90 opacity-50',
  enterToClass: 'rotate-0 opacity-100',
  leaveActiveClass: 'ease-in duration-100',
  leaveFromClass: 'rotate-0',
  leaveToClass: 'opacity-0 rotate-90',
}

const toggleRotate2 = {
  enterActiveClass: 'ease-out duration-200',
  enterFromClass: 'rotate-90 opacity-50',
  enterToClass: 'rotate-0 opacity-100',
  leaveActiveClass: 'ease-in duration-100',
  leaveFromClass: 'rotate-0',
  leaveToClass: 'opacity-0 -rotate-90',
}
</script>
